<template>
  <section>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12" md="12">
        <ag-grid-table :configUrl="historicoDemandaConfig" :dataUrl="historicoDemandaData"
          :editUrl="historicoDemandaEdit">
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
BRow,
BCol,

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import useApiServices from '@/services/useApiServices.js';

import axios from "@axios";

import HistoricoDemandaActions from "./HistoricoDemandaActions.vue";
import RepeatStatistics from "@/views/clients/pf/components/RepeatStatistics";
import NumberCellEditor from "@/views/brunacomponents/ag-grid-table/cell-editors/NumberCellEditor";
import EliminarActions from "./EliminarActions.vue";



export default {
  data() {
    return {


      historicoDemandaConfig: useApiServices.historicoDemandaConfig,
      historicoDemandaData: useApiServices.historicoDemandaData,
      historicoDemandaEdit: useApiServices.historicoDemandaEdit,
      itemsData: [],
    };
  },
  components: {
    BRow,
    BCol,
    AgGridTable,
    historicoDemandaActions: HistoricoDemandaActions,
    RepeatStatistics,
    NumberCellEditor,
    EliminarActions: EliminarActions,
  },

  directives: {
  },

  // Repeat Statistics Cards
  mounted() {
    axios
      .get(useApiServices.historicoDemandaStatistics)
      .then((response) => {
        this.itemsData = response.data;
      })
      .catch((error) => { });
  },

  methods: {



  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

