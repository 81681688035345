<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-primary" @click="showModal">
        <feather-icon icon="AlignJustifyIcon" class="mr-50" />
        <span class="align-middle">Detalles</span>
      </b-button>
    </b-button-group>

    <b-modal
      :ref="'demanda-modal-' + cellValue.data.id"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      title=""
      hide-footer
      @hidden="modalClose"
    >
      <b-card no-body>
        <b-card-body>

          <dl class="row" v-for="(item, index) in data">
            <dt class="col-sm-3">{{ index }}</dt>
            <dd class="col-sm-9">{{ item }}</dd>
          </dl>

        </b-card-body>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import IngresoDemanda from '@/views/clients/pf/carga-formularios/IngresoDemanda.vue'


export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    AgGridTable,
    BrunaElementLoader,
    IngresoDemanda,
    BCard, BCardHeader, BCardTitle, BCardBody
  },

  directives: {
  },

  data: function ()
  {
    return {
      cellValue: null,
      data: null



    };
  },
  beforeMount()
  {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);
    this.data = this.cellValue.data.meta_data;

  },
  methods: {

    modalClose()
    {

    },

    // gets called whenever the user gets the cell to refresh
    refresh(params)
    {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },

    showModal()
    {
      this.$refs['demanda-modal-' + this.cellValue.data.id].show()
    },

    getValueToDisplay(params)
    {
      return params;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
